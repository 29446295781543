/** @format */

import { LogoImg, LogoText, LogoWrapper } from './style';
import LogoAsset from 'assets/imgs/logomini.png';
import LogoAsset2 from 'assets/imgs/logomini.webp';

const Logo = () => {
  const mode = import.meta.env.MODE;

  return (
    <LogoWrapper>
      {mode !== 'pars' && (
        <LogoText>{mode === 'simona' ? 'Simona' : 'Cargo Prime'}</LogoText>
      )}
      {mode === 'pars' ? (
        <LogoImg src={LogoAsset2} width="200px" height="100px" />
      ) : (
        <LogoImg src={LogoAsset} />
      )}
    </LogoWrapper>
  );
};

export default Logo;
