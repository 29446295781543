/** @format */

import styled from 'styled-components';

/* LOGO WRAPPER */
export const LogoWrapper = styled('div')`
  margin: 25px 0px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
`;

export const LogoText = styled('p')`
  font-size: 30px;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
`;

/* LOGO IMG */
export const LogoImg = styled('img')`
  width: ${({ width }) => (width ? width : '70px')};
`;
